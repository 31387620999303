import { Log, Registry, Storage } from '@lightningjs/sdk'
import { CCTypes, ClosedCaptionsUtils } from '../../ClosedCaptions/ClosedCaptionsUtils'

import BasePlatform, {
  PlatformSubscriptionType,
  SubscriptionWrapper,
  TV_PLATFORM_TAG,
} from '../base'
import { KeyCodes, Keys, USER_OPT_OUT_PREFERENCE, PLAYER_SIZE, STORAGE_KEYS } from '../../../constants'
import {
  APP_IDENTIFIER,
  ErrorType,
  IAdvertisingData,
  IKeyMap,
  IStageSettings,
  IVIZIO,
  IVizioDeviceInfo,
  LEMONADE_PLATFORM,
} from '../types'
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js'
import { SupportedPlatforms } from '../../../graphql/generated/types'
import { getRenderPrecision } from '../../../helpers'
import BaseAnnounce from '../../tts/Announces/BaseAnnounce'
import VizioAnnounce from '../../tts/Announces/VizioAnnounce'
import { ShakaPlayerError } from '@sky-uk-ott/core-video-sdk-js-core/lib/players/shaka/shaka-player-error'
import { SessionController } from '@sky-uk-ott/core-video-sdk-js-core'
import { DebugControllerSingleton } from '../../../util/debug/DebugController'

class VizioSubscriptionWrapper extends SubscriptionWrapper {
  _unsubscribeCb: any
  stale = false

  constructor(unsubscribeCb: any) {
    super()
    this._unsubscribeCb = unsubscribeCb
  }

  override unsubscribe(): void {
    this.stale = true
    this._unsubscribeCb?.()
  }
}

const CHIPSET_NOT_AVAILABLE = 'CHIPSET_NOT_AVAILABLE'

export default class VizioPlatform extends BasePlatform {
  override _platformName = 'Vizio'
  override _lemonadePlatform = LEMONADE_PLATFORM.VIZIO
  override _bffPlatform = SupportedPlatforms.Vizio
  override _streamingProtocol = StreamingProtocol.DASH
  override _appIdentifier = APP_IDENTIFIER.VIZIO
  override _subscriptions: VizioSubscriptionWrapper[]
  _userOptOut!: 1 | 0
  _firmwareVersion: any
  _chipsetName: string
  _vsfErrorChipsets = ['5597', '5581P']
  _limitedChipsets = [CHIPSET_NOT_AVAILABLE, '5581', '5581P']

  override get capabilities() {
    return {
      externalAppLinking: true,
      concurrentStreams: true,
    }
  }

  override async init(): Promise<void> {
    this._deviceType = 'Vizio TV'

    if (window.VIZIO) {
      Log.info('window.VIZIO found, adding event listeners')
      this._addVizioEventListeners()
    } else {
      Log.info('window.VIZIO not found, loading companion-lib and adding event listener')
      fetch('http://localhost:12345/scfs/cl/js/vizio-companion-lib.js').catch(
        async () => await super.generateDeviceId()
      )
      Registry.addEventListener(document, 'VIZIO_LIBRARY_DID_LOAD', () => {
        Log.info('window.VIZIO library has loaded, adding event listeners')
        this._addVizioEventListeners()
      })
    }
  }

  private _addVizioEventListeners() {
    window.VIZIO.getDeviceInformation().then((deviceInfo: IVizioDeviceInfo) => {
      Log.info('Vizio DeviceInformation Loaded using getDeviceInformation()')
      if (deviceInfo.SYSTEM_INFO.TVAD_ID) {
        Log.info(
          `Vizio UserOptOut: ${deviceInfo.SYSTEM_INFO.TVAD_ID.LMT}`,
          `AdvertiserID: ${deviceInfo.SYSTEM_INFO.TVAD_ID.IFA}`
        )
        this._userOptOut = deviceInfo.SYSTEM_INFO.TVAD_ID.LMT
        this._advertiserId = deviceInfo.SYSTEM_INFO.TVAD_ID.IFA
      }
      if (deviceInfo.SYSTEM_INFO.CHIPSET_NAME) {
        this._chipsetName = deviceInfo.SYSTEM_INFO.CHIPSET_NAME
      } else {
        this._chipsetName = CHIPSET_NOT_AVAILABLE
      }
    })

    window.VIZIO.setAdvertiserIDListener((AdvertiserID: IAdvertisingData) => {
      Log.info(`AdvertiserID.IFA: ${AdvertiserID.IFA}`, `AdvertiserID.LMT: ${AdvertiserID.LMT}`)
      this._userOptOut = AdvertiserID.LMT
      this._advertiserId = AdvertiserID.IFA
    })

    window.VIZIO.getDeviceId(async (deviceId: string): Promise<void> => {
      Log.info(`Unique Device Id: ${deviceId}`)
      if (this.deviceId) return
      if (deviceId) {
        this.deviceId = deviceId
      } else {
        await super.generateDeviceId()
      }
    })

    window.VIZIO.getFirmwareVersion((firmwareVersion: string) => {
      Log.info(`Device Firmware Version: ${firmwareVersion}`)
      this._firmwareVersion = firmwareVersion
    })
  }

  override getStageSettings(): IStageSettings {
    return {
      precision: 0.6666666666666666,
      devicePixelRatio: window.devicePixelRatio || 1,
    }
  }

  override getPlatformKeyMapping(): IKeyMap {
    return {
      ...this.getAtoZAndNumberKeyMapping(true),
      [KeyCodes.vizio.Exit]: Keys.EXIT,
      [KeyCodes.vizio.Left]: Keys.ARROW_LEFT,
      [KeyCodes.vizio.Up]: Keys.ARROW_UP,
      [KeyCodes.vizio.Right]: Keys.ARROW_RIGHT,
      [KeyCodes.vizio.Down]: Keys.ARROW_DOWN,
      [KeyCodes.vizio.Pause]: Keys.MEDIA_PAUSE,
      [KeyCodes.vizio.Rewind]: Keys.MEDIA_REWIND,
      [KeyCodes.vizio.Stop]: Keys.MEDIA_STOP,
      [KeyCodes.vizio.Play]: Keys.MEDIA_PLAY,
      [KeyCodes.vizio.FastForward]: Keys.MEDIA_FAST_FORWARD,
      [KeyCodes.vizio.MediaTrackNext]: Keys.MEDIA_TRACK_NEXT,
      [KeyCodes.vizio.MediaTrackPrevious]: Keys.MEDIA_TRACK_PREVIOUS,
      [KeyCodes.vizio.AudioVolumeMute]: Keys.VOLUME_MUTE,
      [KeyCodes.vizio.AudioVolumeDown]: Keys.VOLUME_DOWN,
      [KeyCodes.vizio.AudioVolumeUp]: Keys.VOLUME_UP,
      [KeyCodes.vizio.Info]: Keys.INFO,
    }
  }

  override getUserOptOut(): USER_OPT_OUT_PREFERENCE {
    try {
      const storageOptOut = this.getStorageBasedOptOut()
      return this._userOptOut || storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        : USER_OPT_OUT_PREFERENCE.ALLOW_SALE
    } catch (e) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'Error retrieving User Opt Out Preference',
        payload: e,
      })
      return super.getUserOptOut()
    }
  }

  override getAdvertiserId(): string {
    return this._advertiserId || super.getAdvertiserId()
  }

  override subscribe = (evt: any, callback: any): VizioSubscriptionWrapper | undefined => {
    // Filter stale events
    this._subscriptions = this._subscriptions.filter(({ stale }) => !stale)
    if (evt === PlatformSubscriptionType.VOICE) {
      this._getTTSEnabled().then(callback)
      const ttsEnable = () => callback(true)
      const ttsDisable = () => callback(false)
      Registry.addEventListener(document, 'VIZIO_TTS_ENABLED', ttsEnable)
      Registry.addEventListener(document, 'VIZIO_TTS_DISABLED', ttsDisable)

      return new VizioSubscriptionWrapper(() => {
        Registry.removeEventListener(document, 'VIZIO_TTS_ENABLED', ttsEnable)
        Registry.removeEventListener(document, 'VIZIO_TTS_DISABLED', ttsDisable)
      })
    }
    if (evt === PlatformSubscriptionType.CC) {
      if (!window.VIZIO) return
      window.VIZIO.setClosedCaptionHandler((enabled: boolean) => {
        const ccType = enabled ? Storage.get(STORAGE_KEYS.APP_LANGUAGE) || CCTypes.en : CCTypes.off
        ClosedCaptionsUtils.setCCType(ccType)
        callback(enabled)
      })
    }

    return undefined
  }

  override async getModelNumber(): Promise<string> {
    if (window.VIZIO && window.VIZIO.isSmartCastDevice) {
      return window.VIZIO.deviceModel
    }
    return await super.getModelNumber()
  }

  override getFirmware(): string {
    return this._firmwareVersion ?? super.getFirmware()
  }

  override exit(): void {
    super.exit()
    window.VIZIO?.exitApplication()
  }

  override isExitToPeacockSupported(): boolean {
    return !!window.VIZIO
  }

  override exitToPeacock(): void {
    if (!window.VIZIO) {
      super.exitToPeacock()
      return
    }
    try {
      if (window.VIZIO) {
        window.VIZIO?.launchApplication({
          NAME_SPACE: 2,
          APP_ID: '88',
          MESSAGE: '',
        })
      }
    } catch (error) {
      Log.warn(error)
      super.exitToPeacock()
    }
  }

  async _getTTSEnabled(): Promise<boolean> {
    if (window.VIZIO) {
      const deviceManifest = await window.VIZIO.getDeviceManifest()
      if (window.VIZIO.Chromevox) {
        return deviceManifest.tts_enabled
      }
    }
    return false
  }

  /**
   * override video scaling
   * for Vizio, we need to scale down the secondary player but ensure that the full screen video is max
   * @returns {number}
   */
  override scaleVideoProperty(val: number, type: PLAYER_SIZE): number {
    return type === PLAYER_SIZE.SECONDARY ? Math.round(val * getRenderPrecision()) : val
  }

  override tts() {
    return {
      speak(toSpeak: string, notification = false) {
        return window.VIZIO?.Chromevox
          ? new VizioAnnounce(toSpeak, notification)
          : new BaseAnnounce(toSpeak, notification)
      },
      cancel() {
        if (window.VIZIO?.Chromevox) return window.VIZIO.Chromevox.cancel()
        window.speechSynthesis.cancel()
      },
    }
  }

  // we are having problems with linear shaders for some chipsets
  override getAllowLinearGradient() {
    if (this._limitedChipsets.includes(this._chipsetName)) {
      return false
    }
    return true
  }

  override emulateVSFError(sessionController: SessionController | null): void {
    if (sessionController) {
      // The private error method is used because we have no other way to issue a custom error event
      // @ts-expect-error
      sessionController.error(ShakaPlayerError.fromError(
        new shaka.util.Error(
          shaka.util.Error.Severity.CRITICAL,
          shaka.util.Error.Category.MANIFEST,
          shaka.util.Error.Code.VIDEO_ERROR,
          3 as any,
          null as any,
          "PIPELINE_ERROR_DECODE" as any,
        )
      ))
    }
  }

  override getAllowVSFError(): boolean {
    if (DebugControllerSingleton.emulateVSFError) return true
    return this._vsfErrorChipsets.includes(this._chipsetName)
  }
}

declare global {
  interface Window {
    VIZIO: IVIZIO
  }
}
